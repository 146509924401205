.marketplace {
  padding: 2rem;
  margin: 100px 0 50px;
  min-height: calc(100vh - 336px);
}
@media (min-width: 768px) {
  .marketplace {
    padding: 4rem 6rem 4rem 3rem;
    margin: 100px 0 50px;
  }
}
.nft-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  overflow: hidden;
  border-radius: 8px;
  background: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
}

.nft-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  background: #eee;
}
.nft-image-container img.placeholder {
  width: 120px;
  margin: auto;
}

.nft-info {
  padding: 10px 15px 16px;
}

.nft-title {
  font-size: 18px;
  line-height: 32px;
  color: #333;
  margin: 0;
}
.nft-subtitle {
  font-size: 16px;
  margin: 0 0 10px;
  color: grey;
}

.nft-buy-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0 0;
}
.nft-buy-section img {
  width: 20px;
}
.nft-label {
  display: flex;
  gap: 10px;
  font-size: 16px;
  margin: 0;
}

.nft-label img {
  width: 20px;
}

.price-input {
  font-size: 16px;
  line-height: 30px;
  color: #000;
  width: 160px;
  border: 1px solid #838b92;
  height: 30px;
  margin-right: 5px;
  border-radius: 4px;
}

.nft-id {
  background: #e4e4e4;
  border: none;
  border-radius: 4px;
  padding: 2px 10px;
  cursor: pointer;
  color: #20a886;
  font-weight: 700;
}

.nft-price {
  color: #333;
  font-weight: bold;
}

.btn-buy {
  width: 100%;
  background: #20a886;
  border: 1px solid wheat;
  padding: 12px 0;
  cursor: pointer;
  color: white;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}
.btn-buy:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}
.btn-buy:hover {
  background: #137059;
}
.btn-buy.remove {
  background: #ff6c2f;
}
.btn-buy.remove:hover {
  background: #e44d0e;
}

.skeleton {
  width: 300px;
  height: 300px;
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #f3f3f3 8%, #e2e2e2 18%, #f3f3f3 33%);
  background-size: 800px 104px;
  position: relative;
}

.content-nav {
  position: fixed;
  right: 20px;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  flex-direction: column;
  background-color: #ff7d14;
  padding: 30px 5px;
  border-radius: 50px;
}

.content-nav li {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-nav li .btnn {
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  padding: 5px;
  cursor: pointer;
  text-align: center;
  border-radius: 6px;
}

.content-nav li .btnn.active {
  background-color: #fff;
  border: #dcdcdc;
}

.content-nav li .btnn.active .icon-space {
  display: inline-block;
}

.content-nav li .btnn.active .icon-space.dark {
  display: none;
}

.content-nav li .btnn:focus {
  outline: none;
}

@keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.stats {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: start;
  padding: 20px 0;
}
.stats .stat {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
  border-right: 2px solid #a9b5cd;
}
.stats .stat:first-child {
  padding-left: 0;
}
.stats .stat:last-child {
  border-right: none;
}
@media (max-width: 768px) {
  .stats {
    flex-direction: column;
  }
  .stats .stat {
    min-width: 200px;
    padding: 10px;
    border-right: none;
    border-bottom: 2px solid #a9b5cd;
  }
}

.divider-two {
  align-self: stretch;
  margin: 15px 5px;
  height: 2px;

  background-color: rgb(98, 44, 0) !important;
}

select {
  padding: 9px 2rem 10px 15px;
  margin: 0;
  border-radius: 10px;
}
.btn-con {
  color: white;
  border-radius: 12px;
  padding: 12px 30px;
  background: #000 !important;
}

.text-market {
  color: rgb(98, 44, 0) !important;
}
